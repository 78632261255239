import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="conF">FREQUENTLY ASKED QUESTIONS </div>

        <div class="accordin reveal">

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is NFT?</summary>
            <div class="faq__content">
              <p>"Non-fungible token" is referred to as NFT. Non-fungible objects are distinct and cannot be substituted by another object; there are no two or more NFTs that are alike. Digital assets including digital artwork, 3D, GIFs, pictures, movies, audio, and more are easily verifiable.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">Why would I want to own an NFT?</summary>
            <div class="faq__content">
              <p>1. For its rarity, gives you entry to perks and advantages available exclusively to members.</p>
              <p>2. For the pleasure of amassing art. They may stand in for valuable, collectible digital artwork that we can show off and value.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is the "Frenchie Royale" NFT Collection?</summary>
            <div class="faq__content">
              <p>On the Avalanche blockchain, the Frenchie Royale is a collection of 10,000 unique Frenchie NFTs. Since the dawn of humanity, dogs have been our partners. As equal partners in this new digital universe, we bring our actual pets. Together, we open up countless potential in Web3 and the Metaverse. Each NFT is a masterpiece that not only showcases these aristocratic canines but also grants collectors a complimentary vacation experience to iconic destinations.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">Which blockchain are you built on?</summary>
            <div class="faq__content">
              <p>Frenchie Royale is built on the Avalanche blockchain ERC-721.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What is the total supply?</summary>
            <div class="faq__content">
              <p>Frenchie Royale NFT has a total supply of 10,000 unique art French Bulldogs.
                Note: 120 Frenchies will be withheld to be used as raffle prize, community giveaways & team/contributors reward.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">How does the complimentary vacation experience work?</summary>
            <div class="faq__content">
              <p>With every purchase of "Frenchie Royale" NFT, you get access to an exclusive online portal called "THE EMBASSY" where you can redeem your complimentary vacation. Each NFT comes with a specific location as a trait. There are 110 locations around the world.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">How to get "Frenchie Royale" NFTs?</summary>
            <div class="faq__content">
              <p>If you want to get NFTs from the "Frenchie Royale" collection, you can participate in our future sales events. Keep an eye on our official websites and social media channels for announcements of release dates and event details.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary class="summary1">What are the terms and conditions?</summary>
            <div class="faq__content">
              <p>There are NO timeshare presentations required to use this complimentary vacation or any other hoops you will need to jump to redeem. After activation, login online and search for your preferred participating resort/hotel and preferred travel dates.</p>
              
              <p>Travel must be completed within 18 months after activation. This vacation cannot be combined with any other hotel stay or promotional vacation offer. This complimentary vacation cannot be used in conjunction with another similar or same promotional offer.</p>
              
              <p>GROUP travel is NOT allowed only one Vacation per household and resort/hotel is allowed. FRENCHIE ROYALE will only honor one incentive per family, friends, acquaintances, or known groups traveling on the same, similar, and/or adjoining arrival and departure dates. Unfortunately, only 1 party among friends, family, acquaintances, or known groups can use the complimentary vacation for the same or overlapping travel dates. Otherwise, there are additional costs associated with group travel.</p>
              
              <p>The room types available with this vacation are for a maximum of 2 adults and at least 1 occupant must be over the age of 21. The complimentary vacation is designed for couples or singles and up to 2 adults in one room. Additional charges may apply for extra adults or children.</p>

              <p>Your cost to activate and redeem this complimentary vacation and book the reservation includes our recovery fees, local government taxes, and tourism fees which vary by destination and will be satisfied with your NFT purchase. The main reservation holder must also have a Major credit or a bank debit card with a Visa or Mastercard or American Express logo, plus a valid government-issued ID to check-in.</p>

              <p>The recipient will receive luxury hotel accommodation room rate free of charge, which retail is valued at up to $350 US per night, however recipient is responsible for all other expenses including but not limited to: airfare, transfers, food & beverages, gratuities, resort fees if applicable and any other personal or miscellaneous expenses. (Resort fees are now commonplace in hotels around the world however not always applicable). For example in Orlando FL they run from $5.00 to $13.00 per night, In Las Vegas resort fees run from $16.00 to $38 per night Paid directly to the resort.</p>

              <p>All reservations require a minimum of 30 days advance notice to book. Reservations are based on availability and will not be available during major holidays or resort special events. We recommend Sunday - Thursday check-ins for the most availability. Check-in may be available 7 days a week however weekends and some peak season dates may have a small additional surcharge.</p>

              <p>All purchase is FINAL & NON-REFUNDABLE and NON-TRANSFERABLE, the end-user will have 18 months to choose from available travel dates and participating resort/hotel options. Once travel dates are confirmed no changes will be allowed. Cancellations after dates are confirmed will result in forfeiture of the activation fees. (The inventory used for these rooms are not as flexible as booking at regular retail rack rates, once confirmed changes will not be allowed and cancellations or no-shows will result in forfeiture of fees paid)</p>

              <p>The user must reside at least 100 miles or 160 kilometers away from the destination resort of choice or choose a different resort offer from the available destination options.</p>

              <p>FRENCHIE ROYALE reserves the right to offer other hotels with equal or similar value with NFT holder approval if required due to availability. The Hotel bed configuration/ room type is subject to availability upon check-in. (unless a room upgrade was pre-selected and paid for)</p>

              <p>The travel destination can only be redeemed once per household or individual and or guest’s lifetime. FRENCHIE ROYALE at its sole discretion can select to cancel/refund anyone attempting to circumvent these rules.</p>

            </div>
          </details>

        </div>

      </div>


    )
  }
}

export default FAQ;

