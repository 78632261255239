import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/groupPic.png';
import Carousel from './carousel';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2StoryB">
                <div class="conT2">WELCOME TO THE FRENCHIE ROYALE NFT COLLECTION</div>
                <div class="conTSub">Where Aristocracy Meets Adventure</div>
                <div class="about">
                <img src={nft} />
                    <div class="storyCon">
                        <p>The Frenchie Royal collection comprises 10,000 one-of-a-kind digital art Frenchie NFTs living on the Avalanche blockchain. This collection seamlessly combines the extravagant realm of French Bulldogs with the exhilaration of global travel. Each NFT not only showcases these regal canines in various characteristic settings but also grants collectors a complimentary vacation to diverse destinations across the world.</p>
                        <p>Experience the captivating realm of luxury, allure, and worldwide discovery like never before with the debut of the "Frenchie Royale" NFT Collection. Immerse yourself in this extraordinary assortment of digital artworks that showcase majestic French Bulldogs. However, there's more to it than meets the eye - as a collector, you'll gain access to an unparalleled vacation adventure that will whisk you away to renowned destinations at your fingertips. Each Frenchie serves as your membership card, and grants exclusive access to our members-only EMBASSY.</p>
                    </div>                   

                </div>

                <Carousel/>

            </div>
        )
    }
}

export default Story;

