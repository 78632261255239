import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import settings from '../assets/settings.png';
import embassy from '../assets/embassy.png';
import crown from '../assets/crown.png';
import sticky1 from '../assets/sticky1.png';
import sticky2 from '../assets/sticky2.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap2Story3">

                <div class="aboutToken">
 
                    <div class="aboutTokenCon">
                        <div class="conT2">EMBASSY</div>
                       <img class="token" src={embassy} ></img>
                            <p>Embark on a global adventure with every purchase of the "Frenchie Royale" NFT. This virtual masterpiece is more than just an artwork; it's your ticket to travel. Explore renowned destinations such as Paris, Venice, New York City, the beautiful beaches of Mexico, and the opulence of Dubai through our exclusive EMBASSY. And you won't be alone - your faithful Frenchie companion will accompany you every step of the way. Get ready for an extraordinary journey like no other as you embrace this digital passport to unparalleled experiences.</p>
                       </div>

                </div>

                <p class="top"></p>
  
                <div class="aboutToken">
 
                    <div class="aboutTokenCon">
                        <div class="conT2">ROYAL COMMUNITY</div>
                        <img class="token" src={crown} ></img>
                         <p>Connect with other collectors in a dynamic online community. Share your most cherished travel experiences, exchange knowledge, and work together on planning future royal destinations for the adventures of Frenchie. By joining the "Frenchie Royale" community, you are not only a collector but also an adventurer and narrator!</p>
                      </div>

                </div> 
                
                 <p class="top"></p>
  
                <div class="aboutToken">
 
                    <div class="aboutTokenCon">
                        <div class="conT2">THE SPECS</div>
                        <img class="token" src={settings} ></img>
                         <p> Every French Bulldog is one of a kind and created through an automated process that considers potential traits such as facial expression, accessories, outfits, and much more. These distinctive bulldogs are saved as ERC-721 tokens on the Avalanche blockchain and housed on IPFS. Access to the EMBASSY is exclusively granted by logging in with your wallet credentials.</p>
                      </div>

                </div>

                <div class="stickyImages">
                    <img class="sticky1" src={sticky1}/>
                    <img class="sticky2" src={sticky2}/>
                </div>

            </div>
        )
    }
}

export default About;

